@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  background-color: #fafafa;
  font-family: "Inter" !important;
  font-feature-settings: "salt", "zero" !important;
}

.form-width {
  @apply w-full max-w-4xl;
}

input.form-control,
select.form-control,
button.form-control,
textarea.form-control {
  @apply form-width;
  @apply block border border-gray-300 rounded py-2 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500;
}

input.form-control,
textarea.form-control {
  @apply appearance-none;
}

input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched,
input.ng-invalid.ng-dirty,
textarea.ng-invalid.ng-dirty {
  @apply border-red-500;
}

input.form-control:disabled,
textarea.form-control:disabled {
  @apply bg-gray-300 text-gray-600 cursor-not-allowed;
}

.form-heading {
  @apply text-xl font-bold;
}

hr.form-divider {
  @apply my-5;
}

input::placeholder,
textarea::placeholder {
  @apply text-gray-400;
}

*.label {
  @apply block uppercase tracking-wide text-gray-700 text-xs font-bold mb-6 cursor-pointer;
}

*.form-label {
  @apply block tracking-wide mb-2 text-gray-500 cursor-pointer;
}

a.btn {
  @apply inline-block;
}

.btn {
  @apply uppercase text-center bg-gray-300  py-2 px-4 rounded cursor-pointer;
  @apply transition duration-200 ease-in-out;
}

.btn.btn-xs {
  @apply text-xs py-1 px-2;
}

.btn.btn-icon {
  @apply py-0 px-1;
}

.btn.btn-thin {
  @apply py-0 text-sm;
}

.btn.btn-primary {
  @apply bg-primary-600 text-white hover:bg-primary-800 focus:bg-primary-800;
}

.btn.btn-info {
  @apply bg-info-600 text-white hover:bg-info-800 focus:bg-info-800;
}

.btn:disabled,
.btn.disabled {
  @apply bg-gray-300 hover:bg-gray-300 text-gray-600 cursor-not-allowed;
}

.btn.btn-primary-outline {
  @apply bg-white border border-primary-800 text-primary-800;
}

.btn.btn-primary-outline:disabled {
  @apply bg-white border border-gray-500 text-gray-500;
}

.btn.btn-danger {
  @apply bg-error-700 text-white;
}

.btn.btn-danger:hover:not(:disabled) {
  @apply bg-error-700;
}

button:disabled {
  @apply cursor-not-allowed;
}

.centering {
  @apply flex justify-center items-center;
}

@import "tailwindcss/utilities";
